import {Button, Card, CardBody, CardFooter, CardHeader, Flex, Heading, Image, Text} from "@chakra-ui/react";

export default ({icon, title, subtitle, phone, callbackClick, data}: {icon: any, title: string, subtitle: string, phone: string, callbackClick: Function, data: any}) => {
    return <Card cursor={'pointer'} onClick={() => callbackClick(data)}  style={{border: '1px solid #F8AA3C', 'borderRadius': '1px', background: 'transparent'}} height={'176px'} width={'100%'} maxW={'368px'} mb={'30px'} borderRadius={'1px'} display={'flex'} padding={'24px 30px'} borderColor={'brand.primary'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <CardBody display={'flex'} justifyContent={'space-between'} width={'100%'} padding={0}>
            <Flex flexDirection={'column'} justifyContent={'center'}>
                {icon}
            </Flex>
            <Flex flexDirection={'column'}  color={'white'} justifyContent={'center'} maxW={'167px'}>
                <Text fontSize={'26px'} fontWeight={'bold'}>{title}</Text>
                <Text>{subtitle}</Text>
                <Text>{phone}</Text>
            </Flex>
        </CardBody>

    </Card>
}