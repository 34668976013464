import {useFormContext } from 'react-hook-form'
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    useToast,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import {getAccount, isAuth, isBonTacosAccount} from "../../../../services/auth";
import React, {ElementRef, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";

export default ({autoClick}:{autoClick: boolean}) => {
    const account = getAccount()
    const {t} = useTranslation();
    const myRef = useRef(null as null|any);
    const {
        register,
        formState: { errors },
        getValues
    } = useFormContext()
    const navigate = useNavigate()
    const handleCancelOrder = () => navigate('/')
    console.log(getValues())
    useEffect(() => {
        if(!!myRef && autoClick) {
            myRef?.current?.click()
        }
    }, [autoClick]);
    return (
        <Flex direction={'column'} color={'white'}>
            {!isBonTacosAccount() && <> <Input {...register("userId")} defaultValue={account ? account.userId : ''} type={'hidden'}/>
            <FormControl isInvalid={!!errors.orderName}>
                <FormLabel>{t('name')}</FormLabel>
                <Input defaultValue={account.displayName || ''} disabled={!!isAuth()} {...register('orderName', { required: true })} />
                {!!errors.orderName && (
                    <FormErrorMessage>{t('required')}</FormErrorMessage>
                )}
            </FormControl>
            <FormControl isInvalid={!!errors.phoneNumber}>
                <FormLabel>{t('phone')}</FormLabel>
                <Input  defaultValue={!!account ? account.phone : ''} disabled={!!isAuth()}
                       {...register('phoneNumber', {
                           pattern: {value: /\+\d{11}|07\d{7}|\+3891111./, message: t('wrongFormat')}
                    })}
                />
                <FormHelperText color={'white'}>
                    {/*Форматот мора да биде: +38911222333 или 075123123*/}
                </FormHelperText>
                {!!errors.phoneNumber && (
                    <FormErrorMessage>
                        {errors?.phoneNumber?.message?.toString() || ''}
                    </FormErrorMessage>
                )}
            </FormControl></>}

            <Button bgColor={'brand.primary'} type={'submit'} mt={5} ref={myRef}>
                {t('createOrder')}
            </Button>
            <Button mt={5} onClick={() => handleCancelOrder()}>
                {t('startOver')}
            </Button>
        </Flex>
    )
}
