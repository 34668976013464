import React from 'react'
import { Badge, Flex, Image, Text } from '@chakra-ui/react'
import {useTranslation} from "react-i18next";

export default ({
    items,
    handleSelectItem,
}: {
    items: any[]
    handleSelectItem: Function
}) => {
    const {t} = useTranslation();
    return (
        <Flex
            gap={3}
            p={'16px'}
            w={'full'}
            flexWrap={'wrap'}

            height={'fit-content'}
            justifyContent={'space-around'}
        >
            {items.map((item) => {
                return !item.disabled ? <Flex
                    cursor={!item.disabled ? 'pointer': 'default'}
                    direction={'column'}
                    w={{base:'29%', md:'30%'}}
                    maxW={'300px'}
                    alignItems={'flex-start'}
                    opacity={item.disabled ? 0.2 : 1}
                    onClick={() => !item.disabled ? handleSelectItem(item): null}
                    borderRadius={'5px'}
                    p={'3px'}
                    color={'white'}
                    backgroundColor={'rgba(217,217,217,0.2)'}
                >
                    <Image src={item.image} mx={'auto'} />
                    <Text mx={'auto'} fontSize={{base: '10px', md:'18px'}} fontWeight={'bold'} color={'white'} textAlign={'center'}>{t(item.name)}</Text>
                    {!!item.price && (
                        <Text mx={'auto'} fontSize={{base: '10px', md:'18px'}} fontWeight={'bold'} color={'brand.primary'} textAlign={'center'}>{item.price} {t('mkd')}</Text>
                    )}
                </Flex> : null
                }

            )}
        </Flex>
    )
}
