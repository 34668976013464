import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel, HStack,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, PinInput, PinInputField,
    Text,
    useDisclosure,
    useToast,
    VisuallyHidden,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { RootState } from '../../store'
import {
    cancelAction,
    createNewOrder,
    IOrderItem,
    ORDER_STATUS,
    setOrder,
} from './orderFormSlice'
import {
    Controller,
    FormProvider,
    SubmitHandler,
    useForm,
} from 'react-hook-form'
import { calculateTotalPrice } from '../../services/price'
import SelectLocation from './components/SelectLocation/SelectLocation'
import MenuPage, { ProductType } from './components/MenuPage/MenuPage'
import DetailsPage from './components/DetailsPage/DetailsPage'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {fetchAllLocations, selectLocation} from "../../reducers/locations";
import {getAccount, isAuth, isBonTacosAccount, loginUser, setAccount} from "../../services/auth";
import {useTranslation} from "react-i18next";
import {loginAppUser, registerAppUser} from "../../repository/user";
import {startLoading, stopLoading} from "../../components/RegistrationSteps/registrationSlice";

export default () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useAppDispatch()
    const toast = useToast()
    const navigate = useNavigate()
    const {t} = useTranslation();
    let [searchParams, setSearchParams] = useSearchParams()
    const orderForm = useAppSelector((state: RootState) => state.orderForm)
    const [orderStep, setOrderStep] = useState(0)
    const locations = useAppSelector((state: RootState) => state.locations)
    const [createdOrder, setCreatedOrder] = useState(null as null | any)
    const [shouldPrint, setShouldPrint] = useState(false)
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [autoClick, setAutoClick] = useState(false);
    let { id } = useParams();
    useEffect( () => {
        dispatch(fetchAllLocations())

    }, [])
    useEffect(() => {
        if(id && !locations.selectedLocation && locations.data?.length) {
            const foundLocation = locations.data.find(location => location.locationId === id);
            if(foundLocation) {
                dispatch(selectLocation(foundLocation));
            } else {
                navigate('/')
            }
        }
    }, [locations.data]);
    const account = getAccount()
    const {register : registerOTP, setValue: setValueOTP, getValues: getValuesOTP, control: controlOTP, formState: {errors: errorsOTP}, handleSubmit: handleSubmitOTP} = useForm({
        defaultValues: {
            'phone': '',
            'OTP': '',
        }
    });

    const methods = useForm({
        defaultValues: {
            orderPlace: '',
            order: [] as any[],
            name: account ? account.displayName : '',
            orderName: account ? account.displayName : '',
            number: account ? account.phone : '',
            userId: account ? account.userId : '',
            phoneNumber: account ? account.phone : '',
        },
    })
    const onSubmit: SubmitHandler<any> = async (data) => {
        console.log("AUTHENTICATED", !isAuth(), data);
        if(data.phoneNumber.startsWith('0')) {
            data.phoneNumber = data.phoneNumber.replace('0', '+389')
        }
        if(!isAuth()) {
            const number = data.phoneNumber || '';
            const name = data.orderName || '';
            registerAppUser(number, name).then((data:any) => {
                setShowOTPModal(true)
            })
            return;
        }
        try {
            const mappedProducts = data.order.map((orderItem: any) => {
                const foundItem = locations.selectedLocation.menu.find(
                    (item:any) => orderItem.product === item.productId
                )

                return {
                    item: foundItem,
                    quantity: orderItem.quantity,
                    comment: orderItem.comment,
                    extras: orderItem.extras.map((extra: any) =>
                        locations.selectedLocation.menu.find(
                            (item:any) => extra === item.productId
                        )
                    ),
                }
            })

            const createdOrderResponse = await dispatch(
                createNewOrder({
                    ...data,
                    locationId: locations.selectedLocation.locationId,
                    orderPlace: data.orderPlace,
                    items: mappedProducts,
                    userId: getAccount().userId || ''
                })
            ).unwrap()
            toast({
                title: t('creatingOrder'),
                description:
                    t('orderCreatedSuccess'),
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            setTimeout(() => {
                navigate(`/orders/${createdOrderResponse.orderId}`)
            }, 500)
        } catch (e) {
            toast({
                title:  t('creatingOrder'),
                description:
                    t('orderCreatedFail'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }
    const handleLoginViaSMS = (data: { OTP: string}) => {
        let number = methods.getValues('phoneNumber');
        if(number.startsWith('0')) {
            number = number.replace('0', '+389')
        }
        loginAppUser(number, data.OTP).then((data:any) => {
            setShowOTPModal(false)
            dispatch(startLoading())
            setAccount(data.data.payload)
            loginUser(data.data.token)
            dispatch(stopLoading())
            setAutoClick(true);
            console.log("Response data", data)
            toast({
                title: t('loginSuccess'),
                description:
                    t('loginSuccessMessage'),
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        }).catch((e: any) => {
            toast({
                title: t('loginFail'),
                description:
                    t('loginFailMessage'),
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        })
    }

    useEffect(() => {
        if (createdOrder) {
            const drinksAndDesserts = createdOrder?.items?.filter(
                (createdItem: any) =>
                    [ProductType.DRINK, ProductType.DESSERT].includes(
                        createdItem.item.productType
                    )
            )
            if (
                drinksAndDesserts.length &&
                drinksAndDesserts.length === createdOrder.items.length
            ) {
                navigate('/')
            } else {
                setShouldPrint(true)
            }
        }
    }, [createdOrder])


    return (
        <>
            {orderForm.isVisible && (
                <FormProvider {...methods}>
                    <Flex className={'w-full'} justifyContent={'center'} flexDirection={'column'} max-w={'calc(100% - 60px)'} minW={'calc(100% - 60px)'} mx={'auto'}>
                    {/*<Image src={'/images/bon-tacos.jpeg'} height={'150px'} width={'150px'} mx={'auto'} mt={'30px'}  mb={16} />*/}
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        {orderStep === 0 && (
                            <SelectLocation
                                handleClick={(data: string) => {
                                    methods.setValue('orderPlace', data);
                                    setOrderStep(orderStep + 1)
                                }}
                            />
                        )}
                        {orderStep === 1 && (
                            <MenuPage setOrderStep={setOrderStep} />
                        )}
                        {orderStep === 2 && <DetailsPage autoClick={autoClick}/>}
                    </form>
                        <Modal onClose={() => setShowOTPModal(false)} isOpen={showOTPModal} isCentered size={{lg: 'md', base: 'full'}} motionPreset={'slideInBottom'}>
                            <ModalOverlay />
                            <ModalContent>
                                <form>
                                    <Flex direction={'column'} p={15}>
                                        <Text align={'center'} mb={10}>Please enter your activation code sent via SMS</Text>
                                        <Controller control={controlOTP}
                                                    render={({field: {onChange, onBlur, value}}) => <HStack justifyContent={'center'}>
                                                        <PinInput size='lg' onComplete={(data: string) => {
                                                            setValueOTP('OTP', data);
                                                            handleSubmitOTP(handleLoginViaSMS)()
                                                        }}>
                                                            <PinInputField/>
                                                            <PinInputField/>
                                                            <PinInputField/>
                                                            <PinInputField/>
                                                            <PinInputField/>
                                                            <PinInputField/>
                                                        </PinInput>
                                                    </HStack>} name={'OTP'}/>
                                    </Flex>

                                </form>
                            </ModalContent>
                        </Modal>
                    </Flex>
                </FormProvider>
            )}

        </>
    )
}
