import {Box, Flex, Switch, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {getLanguage, setLanguage} from "../../services/auth";
import {changeLanguage} from "i18next";
export enum LANGUAGES {
    EN= 'en',
    MK = 'mk'
}
export default () => {
    const {t} = useTranslation();
    const lang = getLanguage();
    const [languageSelected, setLanguageSelected] = useState(lang)
    const handleLanguageChange = async (lang:LANGUAGES) => {
        setLanguageSelected(lang);
        setLanguage(lang);
        await changeLanguage(lang)
    }
    return <Box p={5} bgColor={'black'} color={'white'} marginTop={'auto'}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
            {/*<Text>{t('copyright') + ` ${new Date().getFullYear()}`}</Text>*/}
            {/*<Flex justifyContent={'center'} alignItems={'center'}>*/}
            {/*    EN <Switch mx={2} isChecked = {languageSelected !== LANGUAGES.EN} onChange={async (e) => {*/}
            {/*        await handleLanguageChange(e.target.checked ? LANGUAGES.MK : LANGUAGES.EN)*/}
            {/*    }}/> MK*/}
            {/*</Flex>*/}
        </Flex>

    </Box>
}