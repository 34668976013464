import { extendTheme } from "@chakra-ui/react"
import './assets/fonts/IntroRust-Base.otf';
// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
    colors: {
        brand: {
            primary: '#F8AA3C',
            text: '#070212'
        },
    },
    fonts: {
        body: 'IntroRust',
        h1: 'IntroRust',
        h2: 'IntroRust',
        h3: 'IntroRust',
    }
})

export default theme;