import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Image,
    Text,
    Input,
    useDisclosure,
    Heading,
    IconButton, Box,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import EatingLocationCard from '../../components/EatingLocationCard/EatingLocationCard'
import MenuItems from './components/MenuItems/MenuItems'
import ItemEditing from './components/ItemEditing/ItemEditing'
import { useFormContext } from 'react-hook-form'
import { mapOrderProducts } from '../../../../services/product'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { useAppSelector } from '../../../../hooks'
import { RootState } from '../../../../store'
import { getAccount } from '../../../../services/auth'
import {calculateTotalPrice} from "../../../../services/price";
import {useTranslation} from "react-i18next";
import TacoCardBig from "./components/MenuItems/TacoCardBig";

export const SUB_MENU = {
    FOOD: 'FOOD',
    DRINK: 'DRINK',
    DESSERT: 'DESSERT',
    COMBO: 'COMBO',
    SEASONAL: 'SEASONAL',
    SIDE: 'SIDE',
}
export default ({
    food,
    drink,
    meat,
    sauce,
    topping,
    sides,
    selectItem,
    selectedItem,
    clearItem,
    dessert,
    combo,
    seasonal,
    changeItemQuantity,
    handleCancelOrder,
    handleConfirmOrder,
}: {
    food: any[]
    drink: any[]
    meat: any[]
    sauce: any[]
    topping: any[]
    sides: any[]
    selectItem: Function
    selectedItem: null | any
    clearItem: Function
    dessert: any[]
    combo: any[]
    seasonal: any[]
    changeItemQuantity: Function
    handleCancelOrder: Function
    handleConfirmOrder: Function
}) => {
    const [selectedSubMenu, setSelectedSubMenu] = useState(SUB_MENU.FOOD)
    const [isOpen, setIsOpen] = useState(false)
    const locations = useAppSelector((state: RootState) => state.locations)
    const userAccount = getAccount();
    const {t} = useTranslation();
    const menu = locations.data.find(
        (location) => location.locationId === userAccount.location
    )
    const form = useFormContext()
    const orderItems = form.getValues('order')
    const totalPrice = calculateTotalPrice(mapOrderProducts(
        orderItems,
        locations.selectedLocation.menu || []
    ));
    return (
        <Flex
            marginTop={'auto'}
            w={'full'}
            justifyContent={'center'}
            flexDirection={'column'}
            mx={'auto'}
            alignItems={'center'}
            p={6}
        >

            <Flex gap={10} justifyContent={'center'} flexDirection={'column'} w={'full'}>
                <Flex
                    direction={'row'}
                    whiteSpace={'nowrap'}
                    gap={5}
                    minW={'100px'}
                    overflowX={'auto'}
                    justifyContent={'center'}
                    px={3}
                >
                    <Flex
                        flexShrink={0}
                        direction={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        borderRadius={'5px'}
                        opacity={selectedSubMenu === SUB_MENU.FOOD ? 1 : 0.5}
                        bgColor={selectedSubMenu === SUB_MENU.FOOD ? 'brand.primary' : 'transparent'}
                        p={'5px'}
                    >
                        <Image
                            cursor={'pointer'}
                            src={'/images/tacos.png'}
                            height={'50px'}
                            width={'auto'}
                            onClick={() => setSelectedSubMenu(SUB_MENU.FOOD)}
                        />
                        <Text textAlign={'center'}  color={'white'}>{t('taco')}</Text>
                    </Flex>
                    {!!seasonal?.length && (
                        <Flex
                            flexShrink={0}
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            borderRadius={'5px'}
                            opacity={
                                selectedSubMenu === SUB_MENU.SEASONAL ? 1 : 0.5
                            }
                            bgColor={selectedSubMenu === SUB_MENU.SEASONAL ? '#E65100' : 'transparent'}
                            p={'5px'}
                        >
                            <Image
                                cursor={'pointer'}
                                src={'/images/seasonal.png'}
                                height={'50px'}
                                width={'auto'}
                                onClick={() =>
                                    setSelectedSubMenu(SUB_MENU.SEASONAL)
                                }
                            />
                            <Text textAlign={'center'}  color={'white'}>{t('seasonalTaco')}</Text>
                        </Flex>
                    )}
                    {!!sides?.length && (
                        <Flex
                            flexShrink={0}
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            borderRadius={'5px'}
                            opacity={
                                selectedSubMenu === SUB_MENU.SIDE ? 1 : 0.5
                            }
                            bgColor={selectedSubMenu === SUB_MENU.SIDE ? '#C89260' : 'transparent'}
                            p={'5px'}
                        >
                            <Image
                                cursor={'pointer'}
                                src={'/images/sides.png'}
                                height={'50px'}
                                width={'auto'}
                                onClick={() =>
                                    setSelectedSubMenu(SUB_MENU.SIDE)
                                }
                            />
                            <Text textAlign={'center'}  color={'white'}>{t('extra')}</Text>
                        </Flex>
                    )}
                    <Flex
                        flexShrink={0}
                        direction={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        borderRadius={'5px'}
                        opacity={selectedSubMenu === SUB_MENU.DRINK ? 1 : 0.5}
                        bgColor={selectedSubMenu === SUB_MENU.DRINK ? '#3ca3f8' : 'transparent'}
                        p={'5px'}
                    >
                        <Image
                            cursor={'pointer'}
                            src={'/images/drinks.png'}
                            height={'50px'}
                            width={'auto'}
                            onClick={() => setSelectedSubMenu(SUB_MENU.DRINK)}
                        />
                        <Text textAlign={'center'}  color={'white'}>{t('drinks')}</Text>
                    </Flex>
                    {!!dessert?.length && (
                        <Flex
                            flexShrink={0}
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            borderRadius={'5px'}
                            opacity={
                                selectedSubMenu === SUB_MENU.DESSERT ? 1 : 0.5
                            }
                            bgColor={selectedSubMenu === SUB_MENU.DESSERT ? '#C860BA' : 'transparent'}
                            p={'5px'}
                        >
                            <Image
                                cursor={'pointer'}
                                src={'/images/dessert.png'}
                                height={'50px'}
                                width={'auto'}
                                onClick={() =>
                                    setSelectedSubMenu(SUB_MENU.DESSERT)
                                }
                            />
                            <Text textAlign={'center'}   color={'white'}>{t('dessert')}</Text>
                        </Flex>
                    )}
                    {!!combo?.length && (
                        <Flex
                            flexShrink={0}
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            borderRadius={'5px'}
                            opacity={
                                selectedSubMenu === SUB_MENU.COMBO ? 1 : 0.5
                            }
                            bgColor={selectedSubMenu === SUB_MENU.COMBO ? '#7AC860' : 'transparent'}
                            p={'5px'}
                        >
                            <Image
                                cursor={'pointer'}
                                src={'/images/combo.png'}
                                height={'50px'}
                                width={'auto'}
                                onClick={() =>
                                    setSelectedSubMenu(SUB_MENU.COMBO)
                                }
                            />
                            <Text textAlign={'center'}  color={'white'}>{t('combo')}</Text>
                        </Flex>
                    )}


                </Flex>
                <Flex  maxW={'600px'} mx={'auto'} w={'full'}>
                    {selectedSubMenu === SUB_MENU.FOOD && (
                        <Flex flexDirection={'column'}>
                            <Box textAlign={'center'} color={'brand.primary'} position={'relative'} style={{transform: 'rotate(-10deg)'}} mb={'50px'}>{t('createYourTaco')}</Box>
                            <TacoCardBig handleSelectItem={selectItem} items={food} />
                        </Flex>

                    )}
                    {selectedSubMenu === SUB_MENU.DRINK && (
                        <MenuItems
                            handleSelectItem={selectItem}
                            items={drink}
                        />
                    )}
                    {selectedSubMenu === SUB_MENU.DESSERT && (
                        <MenuItems
                            handleSelectItem={selectItem}
                            items={dessert}
                        />
                    )}
                    {selectedSubMenu === SUB_MENU.COMBO && (
                        <MenuItems
                            handleSelectItem={selectItem}
                            items={combo}
                        />
                    )}
                    {selectedSubMenu === SUB_MENU.SEASONAL && (
                        <MenuItems
                            handleSelectItem={selectItem}
                            items={seasonal}
                        />
                    )}
                    {selectedSubMenu === SUB_MENU.SIDE && (
                        <MenuItems
                            handleSelectItem={selectItem}
                            items={sides}
                        />
                    )}
                </Flex>
            </Flex>
            <Flex mt={5} justifyContent={'space-between'} flexDirection={'column'} mx={'auto'} w={'full'}
                  maxW={'500px'}>

                <Button
                    bgColor={'brand.primary'}
                    onClick={() => setIsOpen(true)}
                    isDisabled={!orderItems.length}
                    mb={3}
                >
                    {t('viewOrder')}
                </Button>

                <Button onClick={() => handleCancelOrder()} bgColor={'transparent'} color={'darkGray'}>
                    {t('cancelOrder')}
                </Button>
            </Flex>
            {!!selectedItem && (
                <ItemEditing
                    name={`order.${orderItems.length}`}
                    item={selectedItem}
                    itemType={selectedItem?.productType || ''}
                    closeModal={clearItem}
                    meat={meat}
                    sauce={sauce}
                    topping={topping}
                    sides={sides}
                    drinks={drink}
                    closeModalAndSave={() => selectItem(null)}
                />
            )}
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={() => setIsOpen(false)}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton tabIndex={-1} />
                    <DrawerHeader>{t('yourOrder')}</DrawerHeader>

                    <DrawerBody>
                        <Flex
                            className={'w-full'}
                            justifyContent={'center'}
                            flexDirection={'column'}
                            max-w={'50%'}
                            mx={'auto'}
                        >
                            <Image
                                src={'/images/logo.png'}
                                mx={'auto'}
                                mb={5}
                            />
                            <Heading as={'h4'} mb={10}>
                                {t('yourOrder')}
                            </Heading>
                            <Flex
                                justifyContent={'space-around'}
                                flexWrap={'wrap'}
                                flexDirection={'column'}
                            >
                                {mapOrderProducts(
                                    orderItems,
                                    locations.selectedLocation.menu
                                ).map((orderItem: any, itemIndex:number) => (
                                    <Flex
                                        flexDirection={'column'}
                                        borderBottom={'1px'}
                                        mb={4}
                                        pb={2}
                                    >
                                        <Flex
                                            alignItems={'center'}
                                            justifyContent={'space-between'}
                                        >
                                            <Flex alignItems={'center'}>
                                                <Image
                                                    src={
                                                        orderItem?.item
                                                            ?.image
                                                    }
                                                    height={'50px'}
                                                    mr={4}
                                                />{' '}
                                                <Text>
                                                    {t(orderItem?.item?.name)}
                                                </Text>
                                            </Flex>
                                            <Flex alignItems={'center'}>
                                                <IconButton
                                                    boxSize={6}
                                                    isRound={true}
                                                    colorScheme="blue"
                                                    aria-label="Decrease quantity"
                                                    onClick={() => {
                                                        changeItemQuantity(
                                                            itemIndex,
                                                            -1
                                                        )
                                                    }}
                                                    icon={<MinusIcon />}
                                                />
                                                <Text p={3}>
                                                    {orderItem.quantity}
                                                </Text>

                                                <IconButton
                                                    boxSize={6}
                                                    isRound={true}
                                                    colorScheme="blue"
                                                    aria-label="Increase quantity"
                                                    onClick={() => {
                                                        changeItemQuantity(
                                                            itemIndex,
                                                            +1
                                                        )
                                                    }}
                                                    icon={<AddIcon />}
                                                />
                                            </Flex>
                                        </Flex>
                                        <Flex pl={4} flexDirection={'column'}>
                                            {orderItem.extras.map(
                                                (extra: any) => (
                                                    <Flex alignItems={'center'}>
                                                        <Image
                                                            src={extra.image}
                                                            height={'25px'}
                                                            mr={4}
                                                        />{' '}
                                                        <Text>
                                                            {t(extra.name)}
                                                        </Text>
                                                    </Flex>
                                                )
                                            )}
                                        </Flex>
                                    </Flex>
                                ))}
                            </Flex>
                            <Heading as={'h5'} mb={32}>
                                {t('totalToPay')} {totalPrice} {t('mkd')}
                            </Heading>
                        </Flex>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button
                            variant="outline"
                            mr={3}
                            onClick={() => setIsOpen(false)}
                        >
                            {t('close')}
                        </Button>
                        <Button
                            bgColor={'brand.primary'}
                            isDisabled={!orderItems.length}
                            onClick={() => handleConfirmOrder()}
                            tabIndex={1}
                        >
                            {t('makeOrder')}
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </Flex>
    )
}
