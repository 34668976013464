import {
    Badge,
    Button,
    Flex,
    FormControl,
    FormErrorMessage, FormLabel,
    IconButton,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { ProductType } from '../../MenuPage'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { get } from 'lodash'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import {useTranslation} from "react-i18next";

export default ({
    item,
    itemType,
    closeModal,
    meat,
    sauce,
    topping,
    sides,
    name,
    drinks,
    closeModalAndSave,
}: {
    item: any
    itemType: string
    closeModal: Function
    meat: any[]
    sauce: any[]
    topping: any[]
    sides: any[]
    drinks: any[]
    name: string
    closeModalAndSave: Function
}) => {
    const {t} = useTranslation();
    const productIsNotMultiStep = ![
        ProductType.FOOD,
        ProductType.SEASONAL,
        ProductType.COMBO,
    ].includes(item?.productType)
    const [stepForm, setStepForm] = useState(
        item?.productType === ProductType.SEASONAL ? 3 : 1
    )
    const form = useFormContext()
    const buttonRefEnter = useRef<HTMLButtonElement>(null)
    let lastClickedTime = Date.now()
    const productIsMultiStep = [
        ProductType.FOOD,
        ProductType.COMBO,
        ProductType.SEASONAL,
    ].includes(item.productType)

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter' && !!buttonRefEnter) {
            const timeOfClicking = Date.now()
            if (timeOfClicking - lastClickedTime > 1000) {
                console.log('asdasdasd')
                buttonRefEnter?.current?.click()
            }
            lastClickedTime = timeOfClicking
        }
    }

    useEffect(() => {
        document.addEventListener('keyup', handleKeyPress)
        return () => document.removeEventListener('keyup', handleKeyPress)
    }, [])
    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={!!item}
            onClose={() => closeModal()}
            size={'full'}
        >
            <ModalOverlay />
            <ModalContent backgroundColor={'#1c1c1c'} color={'white'}>
                <ModalHeader>{t('selectProduct')}</ModalHeader>
                <ModalCloseButton tabIndex={-1} />
                <ModalBody pb={6}>
                    <Flex direction={'column'} alignItems={'center'}>
                        <Flex>
                            <Image src={item?.image} h={'300px'} hideBelow={'md'}/>
                        </Flex>
                        <Flex>{item?.name}</Flex>
                    </Flex>
                    {[
                        ProductType.FOOD,
                        ProductType.COMBO,
                        ProductType.SEASONAL,
                    ].includes(item?.productType) && (
                        <Flex
                            justifyContent={'center'}
                            flexWrap={'wrap'}
                            mt={5}
                            mx={'auto'}
                        >
                            {stepForm === 1 && (
                                <Flex
                                    flexWrap={`wrap`}
                                    className={'flex1'}
                                    gap={1}
                                    flexDirection={'column'}
                                    p={4}
                                    maxW={'500px'}

                                >
                                    <Text>{`${t('selectMeat')} ${t('maxNumber')}: ${item.maxNumberOfMeats}`}</Text>
                                    <Flex gap={1} flexWrap={'wrap'} justifyContent={'center'}>
                                        {meat?.map((mItem, pos) => (
                                            <EditingItem
                                                item={mItem}
                                                pos={pos}
                                                name={`order.0.extras`}
                                                maxNumber={item.maxNumberOfMeats}
                                                itemsOfType={meat.map(
                                                    (meatItem) => meatItem.productId
                                                )}
                                            />
                                        ))}
                                    </Flex>

                                </Flex>
                            )}
                            {stepForm === 2 && (
                                <Flex
                                    flexWrap={'wrap'}
                                    className={'flex2'}
                                    gap={1}
                                    flexDirection={'column'}
                                    p={4}
                                    maxW={'500px'}
                                >
                                    <Text>{`${t('selectSauce')} ${t('maxNumber')}: ${item.maxNumberOfSauces}`}</Text>
                                    <Flex  gap={1} flexWrap={'wrap'} justifyContent={'center'}>
                                        {sauce?.map((mSauce, pos) => (
                                            <EditingItem
                                                item={mSauce}
                                                pos={pos}
                                                name={`order.0.extras`}
                                                maxNumber={item.maxNumberOfSauces}
                                                itemsOfType={sauce.map(
                                                    (sauceItem) =>
                                                        sauceItem.productId
                                                )}
                                            />
                                        ))}
                                    </Flex>

                                </Flex>
                            )}
                            {stepForm === 3 && (
                                <Flex
                                    flexWrap={'wrap'}
                                    className={'flex3'}
                                    flexDirection={'column'}
                                    gap={1}
                                    p={4}
                                    maxW={'500px'}
                                >
                                    <Text>{`${t('selectTopping')}`}</Text>
                                    <Flex gap={1} flexWrap={'wrap'} justifyContent={'center'}>
                                        {topping?.map((tItem, pos) => (
                                            <EditingItem
                                                item={tItem}
                                                pos={pos}
                                                name={`order.0.extras`}
                                                canAddMultiple={false}
                                                minNumber={0}
                                                maxNumber={100}
                                            />
                                        ))}
                                    </Flex>

                                </Flex>
                            )}
                            {stepForm === 4 &&
                                item.productType === ProductType.COMBO && (
                                    <Flex
                                        flexWrap={'wrap'}
                                        className={'flex5'}
                                        gap={1}
                                        p={4}
                                        maxW={'500px'}
                                        justifyContent={'center'}

                                    >
                                        {drinks?.map((dItem, pos) => (
                                            <EditingItem
                                                item={dItem}
                                                pos={pos}
                                                name={`order.0.extras`}
                                                canAddMultiple={false}
                                                maxNumber={1}
                                                minNumber={1}
                                                itemsOfType={drinks}
                                            />
                                        ))}
                                    </Flex>
                                )}
                            {((stepForm === 4 &&
                                item.productType !== ProductType.COMBO) ||
                                (stepForm === 5 &&
                                    item.productType ===
                                        ProductType.COMBO)) && (
                                <Flex className={'flex4'}>
                                    <FormControl
                                        isInvalid={
                                            !!get(
                                                form.formState.errors,
                                                'order.0.comment'
                                            )
                                        }
                                    >
                                        <FormLabel textColor={'white'}>{t('leaveAComment')}</FormLabel>
                                        <Textarea
                                            {...form.register(
                                                `order.0.comment`
                                            )}
                                        />
                                        {!!get(
                                            form.formState.errors,
                                            'order.0.comment'
                                        ) && (
                                            <FormErrorMessage>
                                                Внеси коментар (пијалок,
                                                барање.. )
                                            </FormErrorMessage>
                                            )}
                                    </FormControl>
                                </Flex>
                            )}
                        </Flex>
                    )}
                    {![
                        ProductType.FOOD,
                        ProductType.COMBO,
                        ProductType.SEASONAL,
                        ProductType.DRINK,
                        ProductType.EXTRAS,
                        ProductType.DESSERT
                    ].includes(item?.productType) && (
                        <Flex
                            justifyContent={'center'}
                            flexWrap={'wrap'}
                            mt={5}
                            flexDirection={'column'}
                            maxW={'500px'}
                            mx={'auto'}
                        >
                            <Text>{t('addComment')}</Text>
                            <Flex>
                                <Textarea
                                    {...form.register(`order.0.comment`)}
                                />
                            </Flex>
                        </Flex>
                    )}
                </ModalBody>

                <ModalFooter  position={'sticky'} bottom={0}>
                    {(productIsNotMultiStep ||
                        (stepForm > 4 && productIsMultiStep) ||
                        (stepForm === 4 &&
                            [ProductType.SEASONAL, ProductType.FOOD].includes(
                                item?.productType
                            ))) && (
                        <>
                        <Flex justifyContent={'center'} flexDirection={'column'} mx={'auto'} w={'full'}
                              maxW={'500px'}>
                            <Button
                                bgColor={'brand.primary'}
                                color={'brand.text'}
                                mb={4}
                                onClick={async () => {
                                    const isValid = await form.trigger()
                                    if (isValid) {
                                        closeModalAndSave()
                                    }
                                }}
                                ref={buttonRefEnter}
                            >
                                Додади
                            </Button>
                            <Button onClick={() => closeModal()}>Откажи</Button>

                        </Flex>
                        </>
                    )}
                    {(([ProductType.FOOD, ProductType.SEASONAL].includes(
                        item.productType
                    ) &&
                        stepForm < 4) ||
                        (item.productType === ProductType.COMBO &&
                            stepForm < 5)) && (
                        <Flex justifyContent={'center'} flexDirection={'column'} mx={'auto'} w={'full'}
                              maxW={'500px'}>

                            <Button
                                bgColor={'brand.primary'}
                                isDisabled={stepForm == 5}
                                onClick={() => setStepForm(stepForm + 1)}
                                ref={buttonRefEnter}
                                w={'full'}
                                maxW={'500px'}
                                mb={4}
                            >
                                {t('next')}
                            </Button>
                            <Button

                                isDisabled={
                                    stepForm == 1 ||
                                    (ProductType.SEASONAL ===
                                        item?.productType &&
                                        stepForm === 3)
                                }
                                onClick={() => setStepForm(stepForm - 1)}
                            >
                                {t('back')}
                            </Button>
                        </Flex>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

const EditingItem = ({
    item,
    name,
    pos,
    canAddMultiple = false,
    maxNumber = -1,
    minNumber = 0,
    itemsOfType = [],
}: {
    item: any
    name: string
    pos: number
    canAddMultiple?: boolean
    maxNumber?: number
    minNumber?: number
    itemsOfType?: any[]
}) => {
    const form = useFormContext()
    const { fields, remove, append } = useFieldArray({
        control: form.control,
        name,
    })
    const {t} = useTranslation();
    const fieldValue = form.getValues(name)
    const buttonRef = useRef<HTMLDivElement>(null)
    let buttonBuffer: string[] = []
    let lastClickedTime = Date.now()
    let keypressTimeout: any = null
    const handleKeyPress = (e: any) => {
        const timeOfClicking = Date.now()
        clearTimeout(keypressTimeout)

        if (timeOfClicking - lastClickedTime > 1000) {
            buttonBuffer = []
        } else if (timeOfClicking - lastClickedTime < 10) {
            return
        }

        buttonBuffer.push(e.key)

        keypressTimeout = setTimeout(() => {
            if (buttonBuffer.join('') === pos + 1 + '' && !!buttonRef) {
                buttonRef?.current?.click()
            }
        }, 200)

        lastClickedTime = timeOfClicking
    }
    const timesAdded = (form.watch(name) || []).reduce(
        (total: number, itemV: any) => {
            return item.productId === itemV ? total + 1 : total
        },
        0
    )

    useEffect(() => {
        document.addEventListener('keyup', handleKeyPress)
        return () => document.removeEventListener('keyup', handleKeyPress)
    }, [])
    return (
        <Controller
            render={({ field: { onChange, onBlur, ref } }) => (
                <Flex
                    cursor="pointer"
                    direction={'column'}
                    w={'95px'}
                    p={4}
                    bgColor={fieldValue.includes(item.productId) ? '#f9aa3e66': '#d9d9d90d'}
                    alignItems={'flex-start'}
                    ref={buttonRef}
                    onClick={() => {
                        if (
                            fieldValue.includes(item.productId) &&
                            !canAddMultiple
                        ) {
                            let index = -1
                            fieldValue.find((fV: string, ind: number) => {
                                if (fV === item.productId) {
                                    index = ind
                                }
                            })
                            remove(index)
                        } else {
                            let itemsAlreadyAdded = 0
                            for (let item of itemsOfType) {
                                if (fieldValue.includes(item)) {
                                    itemsAlreadyAdded += 1
                                }
                            }
                            if (
                                (maxNumber > 0 &&
                                    itemsAlreadyAdded < maxNumber) ||
                                maxNumber <= 0
                            ) {
                                append(item.productId)
                            }
                        }
                    }}
                    borderWidth={
                        fieldValue?.includes(item.productId) ? '1px' : '0px'
                    }
                    borderRadius={'4px'}
                    borderColor={'brand.primary'}
                >
                    {/*<Badge ml={'auto'} variant="outline" colorScheme="blue">*/}
                    {/*    {pos + 1}*/}
                    {/*</Badge>*/}
                    <Image src={item?.image} height={'50px'} mx={'auto'} />
                    <Text mx={'auto'}>{t(item?.name)}</Text>
                    {!!item.price && (
                        <Badge mx={'auto'}>{item.price} {t('mkd')}</Badge>
                    )}
                    {canAddMultiple && timesAdded > 0 && (
                        <Flex
                            justifyContent={'space-between'}
                            mx={'auto'}
                            mt={3}
                        >
                            <IconButton
                                boxSize={6}
                                isRound={true}
                                colorScheme="blue"
                                aria-label="Decrease quantity"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    let index = -1
                                    fieldValue.find(
                                        (fV: string, ind: number) => {
                                            if (fV === item.productId) {
                                                index = ind
                                            }
                                        }
                                    )
                                    remove(index)
                                }}
                                icon={<MinusIcon />}
                            />
                            <Badge>{'x' + timesAdded}</Badge>
                            <IconButton
                                boxSize={6}
                                isRound={true}
                                colorScheme="blue"
                                aria-label="Increase quantity"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    append(item.productId)
                                }}
                                icon={<AddIcon />}
                            />
                        </Flex>
                    )}
                </Flex>
            )}
            name={'test'}
        />
    )
}
