import {Flex, Text, Image, Heading} from "@chakra-ui/react";
import LocationCard from "../../components/LocationCard/LocationCard";
import React, {useState} from "react";
import RegistrationSteps from "../../components/RegistrationSteps/RegistrationSteps";
import {useTranslation} from "react-i18next";

export default ({locations, handleLocationClick}: {locations: any[], handleLocationClick: Function}) => {
    const {t} = useTranslation()
    return <Flex className={'w-full'} justifyContent={'center'} flexDirection={'column'} mx={'auto'}>

        <Flex justifyContent={'space-around'} flexWrap={'wrap'} flexDirection={'row'} width={'100%'} mb={'50px'}>
            {locations.map(location => <LocationCard key={location.locationId} data={location} icon={<Image src={'/images/location-pin.svg'} h={'76px'} w={'76px'}/>}
                                                     title={location.name}
                                                     subtitle={location.address}
                                                     phone={location.phone}
                                                     callbackClick={handleLocationClick}/> )}
        </Flex>
        <Heading as={'h1'} textAlign={'center'} fontSize={'30px'} lineHeight={'36px'}  color={'brand.primary'} >{t('chooseLocation')}</Heading>

    </Flex>
}