import {Flex, Image} from '@chakra-ui/react'
import React from 'react'
import EatingLocationCard from '../EatingLocationCard/EatingLocationCard'
import LocationCard from "../../../../components/LocationCard/LocationCard";
import {useTranslation} from "react-i18next";

export default ({ handleClick }: { handleClick: Function }) => {
    const {t} = useTranslation();
    return (
        <Flex
            justifyContent={'center'}
            flexWrap={'wrap'}
            flexDirection={'column'}
            alignItems={'center'}
            gap={3}
        >
            <LocationCard icon={<svg width="85" height="86" viewBox="0 0 85 86" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M71.7188 24.4062H13.2812C11.8142 24.4062 10.625 25.5955 10.625 27.0625V69.5625C10.625 71.0295 11.8142 72.2188 13.2812 72.2188H71.7188C73.1858 72.2188 74.375 71.0295 74.375 69.5625V27.0625C74.375 25.5955 73.1858 24.4062 71.7188 24.4062Z"
                    stroke="#F8AA3C" strokeWidth="2.7" strokeLinecap="round"/>
                <path
                    d="M29.2188 35.0312V24.4062C29.2188 20.8838 30.618 17.5057 33.1087 15.015C35.5995 12.5243 38.9776 11.125 42.5 11.125C46.0224 11.125 49.4005 12.5243 51.8913 15.015C54.382 17.5057 55.7812 20.8838 55.7812 24.4062V35.0312"
                    stroke="#F8AA3C" strokeWidth="2.7" strokeLinecap="round"/>
            </svg>
            } title={t('takeaway')} subtitle={''} phone={''} callbackClick={handleClick} data={'TAKEAWAY'}/>
            <LocationCard icon={<Image src={'/images/location-pin.svg'} h={'76px'} w={'76px'}/>} title={t('eatIn')}
                          subtitle={''} phone={''} callbackClick={handleClick} data={'EAT_IN'}/>
        </Flex>
    )
}
