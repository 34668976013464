import {
    Box, Button,
    Collapse, Flex, FormControl, FormErrorMessage, FormLabel, HStack, Image, Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, PinInput, PinInputField, Spinner, Text,
    useDisclosure, useToast
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import {isValidPhoneNumber} from "react-phone-number-input";
import {useTranslation} from "react-i18next";
import {loginUser, loginUserRequest, setAccount} from "../../services/auth";
import {activateAppUserAction, resetState} from "../RegistrationSteps/registrationSlice";
import {activateUser} from "../../repository/user";
import {useAppDispatch} from "../../hooks";
const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export default ({isOpenFlag, setIsOpenFlag, openResetPassword}:{isOpenFlag: boolean, setIsOpenFlag: Function, openResetPassword: Function}) => {
    const {t} = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [stepCount, setStepCount] = useState(0)
    const toast = useToast()
    const dispatch = useAppDispatch();

    const handleOpenResetPassword = () => {
        setIsOpenFlag(false)
        openResetPassword(true)
    }
    useEffect(() => {
        if(isOpenFlag) {
            onOpen()
        } else {
            onClose();
        }
    }, [isOpenFlag])

    // useEffect(() => {
    //     return () => {
    //         setStepCount(0);
    //     }
    // })

    const {register, getValues, control, formState: {errors, isValid}, handleSubmit} = useForm();
    const {register : registerOTP, setValue: setValueOTP, getValues: getValuesOTP, control: controlOTP, formState: {errors: errorsOTP}, handleSubmit: handleSubmitOTP} = useForm({
        defaultValues: {
            'phone': '',
            'OTP': '',
        }
    });

    const handleCloseModal = () => {
        setIsOpenFlag(false);
        onClose()
    }

    const submitLoginData = (data: any) => {
        setStepCount(-1);
        loginUserRequest(data).then((resp:any) => {
            console.log(resp);
            dispatch(resetState())
            loginUser(resp.data.token);
            setAccount(resp.data.payload)
            dispatch(resetState())
            toast({
                title: t('profileLogin'),
                description:
                    t('loginSuccess'),
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            setStepCount(0);
            handleCloseModal();
        }).catch((e:any)=> {
            console.log(e);
            if(e.response.status === 417) {
                setTimeout(() => {
                    setStepCount(2)
                    setValueOTP('phone', e.response.data.message)
                    console.log("set step count", stepCount);
                }, 200)

            } else {
                toast({
                    title: t('profileLogin'),
                    description:
                        t('loginFail'),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
                setStepCount(0);
            }
        })

    }


    const handleValidateOTPSubmit = async (data: any) => {
        setStepCount(-1);
        console.log("DATAAAAA", data)
        try {
            await activateUser(data);
            setStepCount(3)
        } catch (e) {
            toast({
                title: t('activationFail'),
                status: "error"
            })
            setStepCount(1)
        }
    }

    return <Modal onClose={handleCloseModal} isOpen={isOpen} isCentered size={{lg: 'md', base: 'full'}} motionPreset={'slideInBottom'}>
        <ModalOverlay />
        <ModalContent>


            <ModalHeader>Login</ModalHeader>
            <ModalCloseButton />
            {[0,1].includes(stepCount) && <form onSubmit={handleSubmit(submitLoginData)}>
            <ModalBody>
                <Flex direction={'column'}>
                    <Image src={'/images/Mobile login-pana.svg'} height={'150px'}/>
                    <Collapse in={stepCount === -1} animateOpacity>
                        <Spinner size={'lg'} position={'relative'} left={'50%'}/>
                    </Collapse>
                    <Collapse in={stepCount === 0} animateOpacity>
                        <FormControl isInvalid={!!errors.email} mb={'10px'}>

                            <FormLabel>
                                Email or phone<span>*</span>
                            </FormLabel>
                            <Input
                                {...register('email', { required: true, })}
                            />
                            {!!errors.email && (
                                <FormErrorMessage>
                                    {t('requiredField')}
                                </FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl isInvalid={!!errors.password} mb={'10px'}>
                            <FormLabel>
                                {t('password')}<span>*</span>
                            </FormLabel>
                            <Input  type='password'
                                {...register('password', { required: true })}
                            />
                            {!!errors.password && (
                                <FormErrorMessage>
                                    {t('requiredField')}
                                </FormErrorMessage>
                            )}

                        </FormControl>
                    </Collapse>
                </Flex>
            </ModalBody>
            <ModalFooter>
                <Flex direction={'column'} w={'100%'}>
                    <Button mb={10} type={'submit'} bgColor='brand.primary' color='brand.text' isDisabled={!isValid}>{t('login')}</Button>
                    <Text color={'deepskyblue'} cursor={'pointer'} as={'span'} ml='auto' onClick={handleOpenResetPassword}>{t('forgotPassword')}</Text>
                </Flex>



            </ModalFooter>
            </form>}
            {stepCount >= 2 && <ModalBody display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                <Collapse in={stepCount === 2} animateOpacity key={stepCount}>
                    <Box
                        p='40px'
                        m={'auto'}
                        rounded='md'
                    >
                        <form>
                            <Text align={'center'} mb={10}>Please enter your activation code sent via SMS</Text>
                            <Controller control={controlOTP} render={({ field: { onChange, onBlur, value } }) => <HStack justifyContent={'center'}>
                                <PinInput size='lg'  onComplete={(data: string)=> {console.log(getValuesOTP());setValueOTP('OTP', data); handleSubmitOTP(handleValidateOTPSubmit)()}}>
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                </PinInput>
                            </HStack>} name={'OTP'}/>
                        </form>
                    </Box>
                </Collapse>
                <Collapse in={stepCount === 3} animateOpacity>
                    <Box
                        p='40px'
                        mt='4'
                        rounded='md'
                    >
                        Registration successful! Please log in!
                    </Box>
                </Collapse>
            </ModalBody>}


        </ModalContent>
    </Modal>
}