import {
    Badge,
    Button,
    Flex,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, Progress,
    Spinner,
    Text,
    Textarea
} from "@chakra-ui/react";
import OrderCard from "../../components/OrderCard/OrderCard";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useReactToPrint} from "react-to-print";
import {ORDER_STATUS} from "../OrderForm/orderFormSlice";
import {Rating} from "react-simple-star-rating";
import dayjs from "dayjs";
import {CheckIcon, CloseIcon, Icon} from "@chakra-ui/icons";
import {isBonTacosAccount} from "../../services/auth";
import {useNavigate} from "react-router-dom";

export default ({order, handleLeaveComment}: {order: null|any, handleLeaveComment: Function}) => {
    const [orderData, setOrderData] = useState(order as null | any);
    const [orderReadyIn, setOrderReadyIn] = useState(order?.readyInMinutes || 20)
    const [ratingModalOpen, setRatingModalOpen] = useState(false);
    const [rating, setRating] = useState(-1);
    const [ratingComment, setRatingComment] = useState('')
    const {t} = useTranslation();
    const componentRef= useRef<HTMLDivElement>(null)
    const timeoutRef = useRef(null as null | any);
    const navigate = useNavigate();
    const handleRating = (rate: number) => {
        setRating(rate)

        // other logic
    }
    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
    const onPointerMove = (value: number, index: number) => console.log(value, index)
    useEffect(() => {
        if(order) {
            setOrderData(order)
            setOrderReadyIn(order?.readyInMinutes || orderReadyIn ||  20)
            if(![ORDER_STATUS.REJECTED, ORDER_STATUS.COMPLETED].includes(order.orderStatus)) {
                timeoutRef.current = setTimeout(() => {
                    window.location.reload();
                }, 10000);
                console.log("Timeout set");
            } else {
                console.log("Timeout cleared");
                clearTimeout(timeoutRef.current)
            }
            if(order.orderStatus === ORDER_STATUS.COMPLETED && !order.userRating?.rating) {
                setRatingModalOpen(true)
            }
            if(isBonTacosAccount()) {
                handlePrint();
                console.log('printer')
                setTimeout(() => {
                    navigate('/')
                }, 300)
            }
        }
    }, [order])
    useEffect(() => {
        setTimeout(() => {
            if(orderReadyIn - 1 <= 2) {
                setOrderReadyIn(3)
            } else {
                setOrderReadyIn(orderReadyIn - 1)
            }
        }, 60000)
    }, [orderReadyIn])
    const handlePrint = useReactToPrint({
        content: () => componentRef?.current
    })
    const mapStatusToValue = (status: string) => {
        console.log(status);
        switch(status) {
            case ORDER_STATUS.CREATED:
                return 20;
            case ORDER_STATUS.ACCEPTED:
            case ORDER_STATUS.IN_PROGRESS:
                return 80
            default:
                return 100;
        }
    }

    return <Flex flexDirection={'column'} w={'full'} pt={2}>
        {/*{!!orderData?.orderId && <Flex justifyContent={'flex-end'} mb={3}>*/}
        {/*    <Text>{t('downloadOrder')} <Icon as={FaFilePdf} fontSize={20} onClick={handlePrint}/></Text>*/}
        {/*</Flex>}*/}
        {!!orderData?.orderId && [ORDER_STATUS.IN_PROGRESS, ORDER_STATUS.ACCEPTED].includes(orderData?.orderStatus) && <Flex my={1} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
            <Flex position={'relative'}>
                <Image src={'/images/splash-screen-logo.svg'} position={'absolute'}/>
                <Spinner
                    speed={'2s'}
                    boxSize={'200px'}
                    borderWidth={'8px'}
                    color="brand.primary"
                    css={{ "--spinner-track-color": "white" }}
                />
            </Flex>
            <Flex><Text mt={10} fontWeight={'bold'} color={'white'}>{t('orderReadyIn')} <span style={{color: '#F9AA3E'}}>{orderReadyIn}</span> {t('minutes')}</Text></Flex>
        </Flex>}
        {!!orderData?.orderId && orderData.orderStatus === ORDER_STATUS.CREATED &&
            <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} my={5}>
                <Text color={'white'}>{t('waitingForConfirmation')}</Text>
                <Progress isIndeterminate width={'calc(100% - 100px)'} maxW={'500px'} colorScheme='orange'/>
            </Flex>}
        {!!orderData?.orderId && orderData.orderStatus === ORDER_STATUS.COMPLETED &&
            <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} my={5}>
                <Text color={'white'}>{t('orderCompleted')}</Text>
                <CheckIcon color={'green'} boxSize={'50px'}/>
            </Flex>}

        {!!orderData?.orderId && orderData.orderStatus === ORDER_STATUS.REJECTED &&
            <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} my={5}>
                <Text color={'white'}>{t('orderRejected')}</Text>
                <CloseIcon color={'red'} boxSize={'50px'}/>
            </Flex>}
        {/*{!!orderData?.orderId && <Flex p={10}>*/}
        {/*    <Card w={'full'} maxW={'600px'} mx={'auto'}>*/}
        {/*        <CardBody>*/}
        {/*            <Flex direction={'row'} justifyContent={'space-between'}>*/}
        {/*                <Box mr={3} mt={'25px'}>*/}
        {/*                    <Text h={'50px'} mt={`${15 + (mapStatusToValue(order.orderStatus) /(order.orderStatus === ORDER_STATUS.REJECTED ? 100 : 200))*100}px`}>{dayjs(order.orderStatus === ORDER_STATUS.CREATED? order.createdAt : order.updatedAt).format('HH:mm')}</Text>*/}
        {/*                </Box>*/}

        {/*                <VerticalProgressBar value={mapStatusToValue(order.orderStatus)} height={order.orderStatus === ORDER_STATUS.REJECTED ? 100 : 200} color={order.orderStatus === ORDER_STATUS.REJECTED ? 'red': 'brand.primary'}/>*/}
        {/*                <Box ml={3} mt={'25px'}>*/}
        {/*                    <Text h={'50px'}>{t('orderCreated')}</Text>*/}
        {/*                    <Text h={'50px'}>{t('orderAccepted')}</Text>*/}
        {/*                    <Text h={'50px'}>{t('orderInProgress')}</Text>*/}
        {/*                    <Text h={'50px'}>{t('orderCompleted')}</Text>*/}
        {/*                </Box>*/}
        {/*            </Flex>*/}
        {/*        </CardBody>*/}
        {/*    </Card>*/}
        {/*</Flex>}*/}
        {orderData?.orderId && <Flex justifyContent={'center'}>
            <Flex h={'max-content'} flexDirection={'column'} gap={2} color={'white'}>
                <Flex><Badge borderRadius='full' colorScheme={'orange'} textAlign={'center'} display={'flex'} justifyContent={'center'} alignItems={'center'} opacity={order.orderStatus === ORDER_STATUS.CREATED ? 1: 0.5}
                             variant='solid'>{t('created')}</Badge> <Text ml={3}>{dayjs(order.createdAt).format('HH:mm')}</Text></Flex>
                {order.orderStatus !== ORDER_STATUS.REJECTED ? <><Flex><Badge borderRadius='full' colorScheme={'green'} textAlign={'center'}  display={'flex'} justifyContent={'center'} alignItems={'center'} opacity={[ORDER_STATUS.IN_PROGRESS, ORDER_STATUS.ACCEPTED].includes(order.orderStatus) ? 1: 0.5}
                                                                              variant='solid'>{t('inProgress')}</Badge>{[ORDER_STATUS.IN_PROGRESS, ORDER_STATUS.ACCEPTED].includes(order.orderStatus) && <Text  ml={3}>{dayjs(order.updatedAt).format('HH:mm')}</Text>}</Flex>
                        <Flex><Badge borderRadius='full' colorScheme={'green'} textAlign={'center'}  display={'flex'} justifyContent={'center'} alignItems={'center'} opacity={order.orderStatus === ORDER_STATUS.COMPLETED ? 1: 0.5}
                                     variant='solid'>{t('completed')}</Badge>{order.orderStatus === ORDER_STATUS.COMPLETED && <Text ml={3}>{dayjs(order.updatedAt).format('HH:mm')}</Text>}</Flex></>:
                    <Flex><Badge borderRadius='full' colorScheme={'red'} textAlign={'center'}  display={'flex'} justifyContent={'center'} alignItems={'center'} opacity={order.orderStatus === ORDER_STATUS.REJECTED ? 1: 0.5}
                                 variant='solid'>{t('rejected')}</Badge>{order.orderStatus === ORDER_STATUS.REJECTED && <Text ml={3}>{dayjs(order.updatedAt).format('HH:mm')}</Text>}</Flex>}
            </Flex>
        </Flex>}

        {!!orderData?.orderId && <Flex ref={componentRef} p={10}><OrderCard order={order}/></Flex>}

        {orderData?.orderStatus === ORDER_STATUS.COMPLETED && !orderData.userRating?.rating && <Modal onClose={() => setRatingModalOpen(false)} isOpen={ratingModalOpen} isCentered size={{lg: 'md', base: 'full'}} motionPreset={'slideInBottom'}>
            <ModalOverlay />
            <ModalContent>


                <ModalHeader>{t('rateUs')}</ModalHeader>
                <ModalCloseButton />
                    <ModalBody>
                        <Flex justifyContent={'center'} alignItems={'center'}>
                            <Image src={'/images/logo.png'}
                            />
                        </Flex>
                        <Flex direction={'column'}>
                            {t('leaveRating')}
                            <Textarea

                                onChange={(e) => setRatingComment(e.target.value)}
                                value={
                                ratingComment
                                }
                                maxLength={180}
                            />

                        </Flex>
                        <Flex justifyContent={'center'} mt={10}>
                            <Rating
                                SVGstyle={{ display: 'inline-block' }}
                                onClick={handleRating}
                                onPointerEnter={onPointerEnter}
                                onPointerLeave={onPointerLeave}
                                onPointerMove={onPointerMove}
                                /* Available Props */
                            />
                        </Flex>

                    </ModalBody>
                    <ModalFooter>
                        <Flex w={'100%'} justifyContent={'space-between'}>
                            <Button mb={10} type={'submit'} color='brand.text' onClick={()=>{setRatingModalOpen(false)}}>{t('cancelRating')}</Button>
                            <Button mb={10} type={'submit'} bgColor='brand.primary' color='brand.text' isDisabled={rating < 1} onClick={() => {handleLeaveComment(rating, ratingComment); setRatingModalOpen(false)}}>{t('submitRating')}</Button>
                        </Flex>



                    </ModalFooter>


            </ModalContent>
        </Modal>}

    </Flex>
}